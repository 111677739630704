.chat-online {
  color: #71cc90;
}

.chat-offline {
  color: #e27c79;
}

.chat-list {
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  overflow-y: scroll;
}

.chat-messages {
  /* display: flex; */
  /* flex-direction: column; */
  max-height: 70vh;
  /* overflow-y: scroll; */
  align-items: center;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}

.chat-new-message {
  position: absolute;
  bottom: 0;
  width: inherit;
}